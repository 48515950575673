// 本地存储模块
export const getItem = (name: any, value?: any) => {
    // 未经处理的请求数据
    const data: any = window.localStorage.getItem(name)
    try {
        // 将data转为js对象
        return JSON.parse(data)
    } catch (error) {
        return data
    }

}
// 存储
export const setItem = (name: any, value: any) => {
    // 如果value是对象，把value转为json字符串存储
    if (typeof value === 'object') {
        value = JSON.stringify(value)
    }
    window.localStorage.setItem(name, value)
}
// 删除
export const removeItem = (name: any) => {
    window.localStorage.removeItem(name)
}
