export default {
    install: app => {
        const Vue = app;
        Vue.directive('preventReClick', {
            mounted(el, binding) {
                el.addEventListener('click', e => {
                    if (!el.disabled) {
                        el.disabled = true;
                        setTimeout(() => {
                            el.disabled = false;
                        }, binding.value || 3000);
                    } else {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                });
            },
        });
    },
};
