<template>
    <div id="app">
        <el-config-provider :locale="zhCn">
            <router-view :key="router.currentRoute.value.fullPath"></router-view>
        </el-config-provider>
    </div>

    <canvas class="canvasBox" id="canvasBox"></canvas>
</template>
<script setup>
import Build from '@g/canvas.js';
import '@/assets/style/common.css';
import '@/assets/style/elementCommon.less';
import { setItem } from '@/common/strogeUtils.ts';
import locale from 'element-plus/lib/locale/lang/zh-cn';

const zhCn = locale;
const router = useRouter();
// console.log(router.currentRoute.value.fullPath);
/* window.addEventListener('hashchange', () => {
         console.log(router.currentRoute.value.fullPath);
         if (router.currentRoute.value.fullPath !== currentPath) {
             router.push(currentPath)
         }
     }, false)*/
setItem('TabsList', []);
onMounted(() => {
    var app = new Build();
    // app.run();

    // let arr = ['/lib/Cesium/Cesium.js', '/lib/Cesium/CesiumNavigation.umd.js'];
    // let externalScript;
    // arr.forEach(item => {
    //     externalScript = document.createElement('script');
    //     externalScript.setAttribute('src', item);
    //     document.body.appendChild(externalScript);
    // });

    // let arr1 = ['/lib/Cesium/Widgets/widgets.css', '/lib/cesium/Widgets/cesium-navigation.css', '/lib/leaflet/leaflet.css'];
    // let externalLink;
    // arr1.forEach(item => {
    //     externalLink = document.createElement('link');
    //     externalLink.setAttribute('href', item);
    //     document.body.appendChild(externalLink);
    // });
});
</script>

<style lang="less" scoped>
/* 当在axios.ts里直接使用ElLoading时页面会报错，需手动引入，但这样做会导致lo ading样式丢失，所以在这里直接引入element-plus全部样式 */
/*@import url(element-plus/dist/index.css);*/
#app {
    position: relative;
    width: 100vw;
    /*height: 13.5rem;*/
    height: 100vh;
    max-height: 100vh;
    min-width: 1366px;
    overflow-y: hidden;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    z-index: 2;
}

canvas {
    opacity: 0.2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    max-height: 99vh;
    /*background-image: -webkit-radial-gradient(ellipse farthest-corner at center center, #1b44e4 0%, #020f3a 100%);*/
    /*background-image: radial-gradient(ellipse farthest-corner at center center, #1b44e4 0%, #020f3a 100%);*/
    cursor: move;
}
</style>
