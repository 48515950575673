import { createApp } from 'vue';
import App from './App.vue';
import 'dayjs/locale/zh-cn';

import router from './router/index';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep'; //深拷贝
import DataVVue3 from '@kjgl77/datav-vue3';
import bus from '@/common/event-bus.js';
import preventReClick from '@/common/preventReClick.js'; // 防止多次点击

import '@/assets/iconfont/iconfont.css'; // 引入阿里云字体图标css
// <i class="iconfont icon-chakan" />;

// 引入 animated 动画
import 'animate.css';
// 这块一定要加,否者会有部分动画无法执行
import 'animate.css/animate.compat.css';

import 'hover.css';

const app = createApp(App);
app.provide('bus', bus);
// 全局配置

// console.log(import.meta.env.VITE_NODE_ENV, 'import.meta.env');
// app.config.globalProperties.$axios = axios

// 防抖：在一段时间内函数只会执行一次，如果在这个时间段内再次触发函数，则重新计算函数执行时间（可以用作提交事件，防止用户重复提交）
// 节流：在一段时间内，函数最多可以触发一次执行（滚动加载的时候，当滑动到底部时，一直滑动那么每隔一定时间就会执行一次加载）
// app.use(animated);
app.use(preventReClick);
app.use(DataVVue3);
app.use(router);
app.mount('#app');
