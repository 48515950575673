import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router';
import homeRouter from './home';
import login from '@/view/Login.vue';
import home from '@c/home.vue';
import main from '@/view/Main.vue';
import deviceMessage from '@c/deviceMonitor/deviceMessage.vue';
import deviceStatus from '@c/deviceMonitor/deviceStatus.vue';
import deviceSensorStatus from '@c/deviceMonitor/deviceSensorStatus.vue';
import reportProcessLine from '@c/datagraphic/reportProcessLine.vue';
import queryTotalReport from '@c/datagraphic/queryTotalReport.vue';
import queryDistributionChart from '@c/datagraphic/queryDistributionChart.vue';
import queryThreeDChart from '@c/datagraphic/queryThreeDChart.vue';
import dailyReport from '@c/datagraphic/dailyReport_list.vue';
import queryWarnSendLogList from '@c/warning/queryWarnSendLogList.vue';
import tourRecordList from '@c/tour/tourRecordList.vue';
import queryCorrelationChart from '@c/dataAnalysis/queryCorrelationChart.vue';
import queryCompareDataChart from '@c/dataAnalysis/queryCompareDataChart.vue';
import queryModelAnalysis from '@c/dataAnalysis/queryModelAnalysis.vue';
import queryLinearRegressionAnalysisIndex from '@c/dataAnalysis/queryLinearRegressionAnalysisIndex.vue';
import queryRainStatistics from '@c/dataAnalysis/queryRainStatistics.vue';
import projectInfo from '@c/project/projectInfo.vue';

// type RouteConfig = RouteRecordRaw & { hidden?: boolean, centersild?: string | number, }; //hidden 是可选属性
const baseroutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/Login',
    },
    {
        path: '/Register',
        name: 'Register',
        component: () => import('@/view/Register.vue'),
        meta: {
            requireAuth: true, //经过身份验证可访问
        },
    },
    {
        path: '/Login',
        name: 'Login',
        // component: login, //
        component: () => import('@/view/Login.vue'),
        meta: {
            requireAuth: true, //经过身份验证可访问
        },
    },
    {
        path: '/',
        // component: main,
        component: () => import('@/view/Main.vue'),
        hidden: true,
        meta: {
            requireAuth: true, //经过身份验证可访问
            title: '首页',
        },
        children: [
            {
                path: 'home',
                name: 'home',
                // component: home,
                component: () => import('@c/home.vue'),
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title: '首页',
                },
            },
            {
                path: 'deviceMessage',
                name: 'deviceMessage',
                // component: deviceMessage, //
                component: () => import('@c/deviceMonitor/deviceMessage.vue'),
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title: '设备数据',
                },
            },
            {
                path: 'deviceStatus',
                name: 'deviceStatus',
                // component: deviceStatus,
                component: () => import('@c/deviceMonitor/deviceStatus.vue'),
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title: '采集仪状态',
                },
            },
            {
                path: 'deviceSensorStatus',
                name: 'deviceSensorStatus',
                // component: deviceSensorStatus, //
                component: () => import('@c/deviceMonitor/deviceSensorStatus.vue'),
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title: '传感器状态',
                },
            },
            {
                path: 'reportProcessLine',
                name: 'reportProcessLine',
                // component: reportProcessLine, //
                component: () => import('@c/datagraphic/reportProcessLine.vue'),
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title: '报表过程线',
                },
            },
            {
                path: 'queryTotalReport',
                name: 'queryTotalReport',
                // component: queryTotalReport, //
                component: () => import('@c/datagraphic/queryTotalReport.vue'),
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title: '统计报表',
                },
            },
            {
                path: 'queryDistributionChart',
                name: 'queryDistributionChart',
                // component: queryDistributionChart, //
                component: () => import('@c/datagraphic/queryDistributionChart.vue'),
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title: '分布图',
                },
            },
            {
                path: 'queryThreeDChart',
                name: 'queryThreeDChart',
                // component: queryThreeDChart, //
                component: () => import('@c/datagraphic/queryThreeDChart.vue'),
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title: '多维度变形',
                },
            },
            {
                path: 'dailyReport',
                name: 'dailyReport',
                // component: dailyReport, //
                component: () => import('@c/datagraphic/dailyReport_list.vue'),
                meta: {
                    requireAuth: true, //经过身份验证可访问
                    keepAlive: true,
                    title: '日报查询',
                },
            },
            {
                path: 'queryWarnSendLogList',
                name: 'queryWarnSendLogList',
                // component: queryWarnSendLogList, //
                component: () => import('@c/warning/queryWarnSendLogList.vue'),
                meta: {
                    requireAuth: true, //经过身份验证可访问
                    keepAlive: true,
                    title: '预警查询',
                },
            },
            {
                path: 'queryTourRecordList',
                name: 'queryTourRecordList',
                // component: tourRecordList, //
                component: () => import('@c/tour/tourRecordList.vue'),
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title: '巡视检查',
                },
            },
            {
                path: 'queryCorrelationChart',
                name: 'queryCorrelationChart',
                // component: queryCorrelationChart, //
                component: () => import('@c/dataAnalysis/queryCorrelationChart.vue'),
                meta: {
                    requireAuth: true, //经过身份验证可访问
                    keepAlive: true,
                    title: '相关分析',
                },
            },
            {
                path: 'queryCompareDataChart',
                name: 'queryCompareDataChart',
                // component: queryCompareDataChart, //
                component: () => import('@c/dataAnalysis/queryCompareDataChart.vue'),
                meta: {
                    requireAuth: true, //经过身份验证可访问
                    keepAlive: true,
                    title: '对比分析',
                },
            },
            {
                path: 'queryModelAnalysis',
                name: 'queryModelAnalysis',
                // component: queryModelAnalysis, //
                component: () => import('@c/dataAnalysis/queryModelAnalysis.vue'),
                meta: {
                    requireAuth: true, //经过身份验证可访问
                    keepAlive: true,
                    title: '灰模型分析',
                },
            },
            {
                path: 'queryLinearRegressionAnalysisIndex',
                name: 'queryLinearRegressionAnalysisIndex',
                // component: queryLinearRegressionAnalysisIndex, //
                component: () => import('@c/dataAnalysis/queryLinearRegressionAnalysisIndex.vue'),
                meta: {
                    requireAuth: true, //经过身份验证可访问
                    keepAlive: true,
                    title: '统计模型分析',
                },
            },
            {
                path: 'queryRainStatistics',
                name: 'queryRainStatistics',
                // component: queryRainStatistics, //
                component: () => import('@c/dataAnalysis/queryRainStatistics.vue'),
                meta: {
                    requireAuth: true, //经过身份验证可访问
                    keepAlive: true,
                    title: '雨情统计',
                },
            },
            {
                path: 'queryVideoPhoto',
                name: 'queryVideoPhoto',
                // component: queryVideoPhoto, //
                component: () => import('@c/video/queryVideoPhoto.vue'),
                meta: {
                    requireAuth: true, //经过身份验证可访问
                    keepAlive: true,
                    title: '定时拍照',
                },
            },
            {
                path: 'queryG2VideoMonitorP',
                name: 'queryG2VideoMonitorP',
                // component: queryVideoPhoto, //
                component: () => import('@c/video/queryG2VideoMonitorP.vue'),
                meta: {
                    requireAuth: true, //经过身份验证可访问
                    keepAlive: true,
                    title: 'G2实时视频',
                },
            },

            {
                path: 'projectInfo',
                name: 'projectInfo',
                // component: projectInfo,
                component: () => import('@c/project/projectInfo.vue'),
                meta: {
                    requireAuth: true, //经过身份验证可访问
                    keepAlive: true,
                    title: '工程信息',
                },
            },
        ],
    },
];
const routes: Array<RouteRecordRaw> = baseroutes; //.concat(homeRouter);
const router = createRouter({
    // history: createWebHistory(),
    history: createWebHashHistory(),
    routes,
    // scrollBehavior() {
    //     return {
    //         x: 0,
    //         y: 0,
    //     };
    // },
});

/*router.beforeEach((to, from, next) => {
    // next();
    if (to.path == '/Login') {
        next();
    } else {
        if(getToken('username') == undefined || getToken('username') == ''){
          next('/Login');
        }else{
        next();
        }
    }
});*/
export default router;
